<template>

  <div>
  
    <div class="card-header">
      <h3 class="card-title">
        {{msg}}
      </h3>
  
    </div>
  
  
    <div class="row" id="labelme">
      <div class="col-lg-9">
        <draw-board ref="board"
          v-bind.sync="drawBoardConfig"
          :zoom-config="zoomConfig"
          :width="width"
          :height="height"
          :graph-object-list.sync="graphObjectList"
          :image-width.sync="imageInfo.imageWidth"
          :image-height.sync="imageInfo.imageHeight"
          :image-url="imageInfo.imageUrl">
        </draw-board>
      </div>
  
      <div class="col-lg-3">
        <div class="" @click="$refs['board'].clearSelect()">
          <el-divider></el-divider>
  
          <div style="display:flex;justify-content:space-between;align-item:center">
          </div>
  
          <div style="display:flex;justify-content:space-between;align-item:center">
            <el-upload class="" action="#" :show-file-list="false"
                :http-request="changeTagImage">
              <el-button slot="trigger" type="text">
                <h4 style="margin:0;padding:0 20px">
                  点击上传图片
                </h4>
              </el-button>
            </el-upload>
          </div>
  
  
          <el-divider></el-divider>
  
          <el-table :data="tableData" border
              height="900"
              style="width: 100%;"
              @cell-mouse-enter="focus"
              @cell-mouse-leave="unfocus"
              @cell-dblclick="changeInput">
  
            <el-table-column label="序号" type="index" align="center" width="60">
            </el-table-column>
  
            <el-table-column label="文本" align="center">
              <template slot-scope="scope">
                <el-input v-model="scope.row.name"
                  @keyup.native.enter="scope.row.edit=false"
                  @input="e=>handleGraphNameChange(scope.row,e)">
                </el-input>
              </template>
            </el-table-column>
  
            <el-table-column label="类别" align="center">
              <template slot-scope="scope">
                <el-input v-model="scope.row.categ"
                  @keyup.native.enter="scope.row.edit=false"
                  @input="e=>handleGraphNameChange(scope.row,e)">
                </el-input>
              </template>
            </el-table-column>
  
            <el-table-column label="操作" align="center">
              <template slot-scope="scope">
  
                <el-button type="text" @click="toggleGraphShow(scope.row)">
                  {{ scope.row.visible?'隐藏':'显示' }}
                </el-button>
  
                <el-button type="text" style="color:red;" @click="deleteGraph(scope.row,scope.$index)">
                  删除
                </el-button>
  
              </template>
            </el-table-column>
  
          </el-table>
        </div>
      </div>
    </div>
  </div>
  
  </template>
  
  <script>
  
  const ModeMap = new Map([
    ["upload", "导入"],
    ["drag", "拖拽"],
    ["rect", "矩形"],
    ["poly", "多边形"],
    ["line", "直线"],
    ["ellipse", "圆形"],
  ]);
  
  import { saveJSON } from "@/components/Labelme/utils/utils";
  import DrawBoard from "@/components/Labelme/components/DrawBoard.vue"
  import "@/components/Labelme/iconfont.css"
  const axios = require("axios")
  
  export default {
    name: "",
    components: { DrawBoard },
    data() {
      return {
        msg: "车牌识别",
        width: 1200,
        height: 1000,
        imageInfo: {
          imageUrl: "",
          imageName: "",
          imageData: "",
          imageHeight: 0,
          imageWidth: 0,
        },
  
        graphObjectList: [],
        drawBoardConfig: {
          mode: "drag",
          zoom: 1,
          ratio: 1,
          bitScale: 1,
          resultObject: {},
        },
        zoomConfig: {
          zoomFactor: 0.1,
          zoomMin: 0.5,
          zoomMax: 20,
        },
      };
    },
    computed: {
      currentMode() {
        return ModeMap.get(this.drawBoardConfig.mode);
      },
  
      tableData() {
        return this.graphObjectList.map((graph) => {
          let { name, id, categ, type } = graph.attr();
          name = name.slice(1, name.length)
          return {
            name,
            id,
            categ,
            type,
            visible: true,
            mountedDOM: graph,
            edit: false,
          };
        });
      },
    },
  
    mounted() {
      const editor = document.getElementById("labelme");
      //this.width = editor.offsetWidth - 420;
      this.width = editor.offsetWidth;
      this.height = editor.offsetHeight;
      this.$nextTick(() => {
        this.$refs["board"].resize();
      });
  
      document.addEventListener("fullscreenchange", () => {
        console.log(document.fullscreenElement)
        if (!document.fullscreenElement) {
  
          this.$nextTick(() => {
            this.$refs["board"].resize();
          });
        }
      });
    },
  
    methods: {
  
      fullScreen() {
        const editor = document.getElementById("labelme");
        // console.log(document.fullscreenElement);
        if (!document.fullscreenElement) {
          this.width = screen.width - 420;
          this.height = screen.height;
          editor.requestFullscreen();
          this.$nextTick(() => {
            this.$refs["board"].resize();
          });
        } else {
          document.exitFullscreen();
        }
      },
  
      reLabel() {
        this.graphObjectList.forEach((el) => el.remove());
        this.graphObjectList = [];
      },
  
      handleGraphNameChange(row, e) {
        row.mountedDOM.attr({
          name: e,
        });
      },
  
      changeTagImage(param) {
        this.reLabel();
  
        this.$nextTick(()=>{
          const { file } = param;
          this.graphObjectList = [];
          const imageUrl = URL.createObjectURL(file);
          this.$refs["board"].changeLabelImage(imageUrl);
          this.imageInfo.imageUrl = imageUrl;

          this.imageInfo.imageName = file.name.split(".")[0];
          const fr = new FileReader();
          fr.readAsDataURL(file);
          fr.onload = () => {
            this.imageInfo.imageData = fr.result.split("base64,")[1];
  
            axios({
              headers: {
                "Content-type":"application/json",
                "Token": "webq7#5hn9N",
              },
              method:"post",
              url: `/api/licensePlate`,
              data: JSON.stringify({ "image": this.imageInfo.imageData, })
            }).then((response)=>{
                //console.log(response)
                let shapes = response['data']['shapes']
  
                let bitScale = this.drawBoardConfig.bitScale;
  
                let pointsArray = []
                let textArrary = []
                let categoryArray = []
                let scoreArray = []
  
                for(var i=0; i<shapes.length; i++){
                  let shapesData = shapes[i]
                  let shape = shapesData['points']
                  let label = shapesData['label']
                  let points = [shape[0][0], shape[0][1], shape[1][0], shape[1][1]]
                  points = points.map(x=> x / bitScale)
                  pointsArray.push(points)
                  textArrary.push(label['text'])
                  categoryArray.push(label['category'])
                  scoreArray.push(label['score'])
                }
  
                this.drawBoardConfig.mode="upload"
                this.drawBoardConfig.resultObject = {
                  "task": "kie",
                  "bboxes": pointsArray,
                  "category": categoryArray,
                  "text": textArrary,
                  "score": scoreArray,
                }
  
              })
          };
        })
      },
  
      changeTagJson(param) {
        let aa=this;
  
        const { file } = param;
        this.graphObjectList = [];
        const tagUrl = URL.createObjectURL(file);
  
        const fr = new FileReader();
        fr.readAsText(file);
        fr.onload = () => {
          let frData = JSON.parse(fr.result)
          let shapes = frData['shapes']
          let pointsArray = []
          let bitScale = this.drawBoardConfig.bitScale;
          for(var i=0; i<shapes.length; i++){
            let shape = shapes[i]['points']
            let points = [shape[0][0], shape[0][1], shape[1][0], shape[1][1]]
            points = points.map(x=> x / bitScale)
            pointsArray.push(points)
          }
  
          this.drawBoardConfig.mode="upload"
          this.drawBoardConfig.resultObject = {
            "bboxes": pointsArray
          }
  
        };
  
      },
  
      focus(row) {
        row.mountedDOM.attr({
          stroke: "red",
          "stroke-width": 3,
        });
      },
  
      unfocus(row) {
        const color = row.mountedDOM.attr("color");
        row.mountedDOM.attr({
          stroke: color,
        });
      },
  
      toggleGraphShow(row) {
        row.visible ? row.mountedDOM.hide() : row.mountedDOM.show();
        row.visible = !row.visible;
      },
  
      deleteGraph(row, index) {
        row.mountedDOM.remove();
        this.graphObjectList.splice(index, 1);
      },
  
      changeInput(row) {
        row.edit = true;
        this.tableData.forEach((graph) => {
          if (graph.id !== row.id) {
            graph.edit = false;
          }
        });
      },
  
      rotateImage() {
        const originImageURL = this.imageInfo.imageUrl;
        const image = new Image();
        image.src = originImageURL;
        image.onload = () => {
          const canvas = document.createElement("canvas");
          canvas.width = image.height;
          canvas.height = image.width;
          const ctx = canvas.getContext("2d");
          ctx.rotate(0.5 * Math.PI);
          ctx.translate(0, -canvas.width);
          ctx.drawImage(image, 0, 0);
          const dataUrl = canvas.toDataURL();
          this.imageInfo.imageData = dataUrl.split("base64,")[1];
          this.imageInfo.imageUrl = dataUrl;
          this.$refs["board"].changeLabelImage(dataUrl, true);
        };
      },
  
      // rotateLable() {
      //   this.graphObjectList.forEach((graph) => {
      //     if (graph.type === "rect") {
      //       const { x, y, width, height } = graph.attr([
      //         "x",
      //         "y",
      //         "width",
      //         "height",
      //       ]);
      //       console.log([x,y].map(el=>Math.round(el*this.drawBoardConfig.bitScale)))
      //       const {x:x1,y:y1} = rotateLocation(x*this.drawBoardConfig.bitScale,(y+height)*this.drawBoardConfig.bitScale,{x:0,y:0})
      //       console.log(Math.round(411-x1),Math.round(y1))
      //       graph.attr({
      //         x: y1,
      //         y: x1,
      //         width: height,
      //         height: width,
      //       });
      //     }
      //   });
      // },
  
      exportJSON() {
        if (this.imageInfo.imageUrl !== "") {
          const shapes = this.tableData.map((row) => {
            const graph = row.mountedDOM;
            const graphType = graph.attr("type");
            const vectorMap = [];
            if (graphType === "rectangle") {
              const { x, x2, y, y2 } = graph.bbox();
              vectorMap.push([x, y]);
              vectorMap.push([x2, y2]);
            } else if (graphType === "polygon") {
              const points = graph.array();
              points.pop();
              points.forEach((point) => {
                vectorMap.push(point);
              });
            }
  
            const bitMap = vectorMap.map((point) => {
              return point.map((num) => num * this.drawBoardConfig.bitScale);
            });
            return {
              label: row.name,
              points: bitMap,
              shape_type: graphType,
            };
          });
  
          const { imageName, imageData, imageHeight, imageWidth } = this.imageInfo;
          saveJSON(
            JSON.stringify({
              //version: "priu_online_tag_tool",
              //flag: {},
              shapes,
              imagePath: imageName,
              //imageData,
              imageWidth,
              imageHeight,
            }),
            imageName
          );
        } else {
          this.$message.warning("请先上传图片");
        }
      },
  
    },
  };
  
  </script>
  
  <style scoped>
  .labelme {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    background: white;
  }
  
  .labelme >>> .el-divider {
    margin: 4px 0;
  }
  
  .tools {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .tools >>> .el-button {
    font-size: 18px;
    margin: 0;
    padding: 15px 25px;
    color: gray;
  }
  
  .active >>> i {
    color: blue;
  }
  
  .card {
    width: 350px;
  }
  
  .card >>> .el-table {
    font-size: 14px;
  }
  
  .card >>> .el-table--medium th {
    padding: 5px;
  }
  
  .card >>> .el-table--medium td {
    padding: 5px;
  }
  </style>
  